import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import './style/styles.css';

const Home = () => {
  const [memories, setMemories] = useState([]);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/index.php/memories`)
      .then(response => setMemories(response.data))
      .catch(error => console.error('There was an error fetching the memories!', error));
  }, []);

  return (
    <div className="container mt-4">
      <header className="text-center mb-4">
        <h1 className="wedding-title">{process.env.REACT_APP_WELCOME_TEXT}</h1>
      </header>
      <div className="row">
        {memories.map((memory, index) => (
          <div key={index} className="col-md-4 col-sm-6 mb-4">
            <Link to={`/memory/${memory.id}`}>
              <div className="card h-100 shadow-sm memory-card">
                {memory.files[0] && memory.files[0].type.startsWith('image') && (
                  <img
                    src={`${process.env.REACT_APP_API_URL}/uploads/${memory.id}/${memory.files[0].filename}`}
                    className="card-img-top memory-image"
                    alt={memory.files[0].originalname}
                  />
                )}
                <div className="card-body">
                  <h5 className="card-title">{memory.title}</h5>
                  <p className="card-text">{memory.description.substring(0, 50)}...</p>
                  <Link to={`/memory/${memory.id}`} className="btn btn-primary">Anıyı Gör</Link>
                </div>
              </div>
            </Link>
          </div>
        ))}
      </div>
      <div className="d-flex justify-content-center mt-4">
        <Link to="/upload" className="btn btn-primary mx-2 upload-button">
          Anı Yükle
        </Link>
        <Link to="/home" className="btn btn-secondary mx-2 upload-button">
          Tüm Anıları Göster
        </Link>
      </div>
    </div>
  );
};

export default Home;
