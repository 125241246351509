import React, { useState } from 'react';
import axios from 'axios';
import { useDropzone } from 'react-dropzone';
import 'bootstrap/dist/css/bootstrap.min.css';
import './style/styles.css';
import { useNavigate } from 'react-router-dom';

const Upload = () => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [files, setFiles] = useState([]);
  const [uploadProgress, setUploadProgress] = useState({});
  const [isUploading, setIsUploading] = useState(false);
  const navigate = useNavigate();

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*, video/mp4, video/quicktime, .mov',
    onDrop: acceptedFiles => {
      acceptedFiles.forEach(file => {
        console.log(`Accepted file: ${file.name}, type: ${file.type}, size: ${file.size}`);
      });
      setFiles([...files, ...acceptedFiles]);
    }
  });
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsUploading(true); 

    const formData = new FormData();
    formData.append('title', title);
    formData.append('description', description);
    files.forEach(file => formData.append('files[]', file));

    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/index.php/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          const percentCompleted = Math.floor((loaded * 100) / total);
          setUploadProgress((prevProgress) => ({
            ...prevProgress,
            overall: percentCompleted,
          }));
        }
      });
      alert('Anınız başarıyla yüklendi!');
      navigate('/home');
    } catch (error) {
      console.error('There was an error uploading the memory!', error);
      alert('Yükleme sırasında bir hata oluştu. Lütfen tekrar deneyin.',error);
      alert('Yükleme sırasında bir hata oluştu. Lütfen tekrar deneyin.');
    } finally {
      setIsUploading(false); 
    }
  };

  return (
    <div className="container mt-4">
      <h1 className="text-center">Anılarımızı Yükleyiniz</h1>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Başlık <span className='text-danger'> *Zorunlu Değil</span></label>
          <input
            type="text"
            className="form-control"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            //required
          />
        </div>
        <div className="form-group">
          <label>Bizlere Notunuz <span className='text-danger'> *Zorunlu Değil</span> </label>
          <textarea
            className="form-control"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            //required
          />
        </div>
        <div {...getRootProps()} className="form-group border p-4 text-center">
          <input {...getInputProps()} />
          <p>Galerinizden Anılarımızı Seçmek için Tıklayınız.</p>
        </div>
        <ul className="list-group mb-3">
          {files.map((file, index) => (
            <li key={index} className="list-group-item d-flex justify-content-between align-items-center">
              {file.name}
              <div className="progress" style={{ width: '50%' }}>
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{ width: `${uploadProgress.overall || 0}%` }}
                  aria-valuenow={uploadProgress.overall || 0}
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                  {uploadProgress.overall || 0}%
                </div>
              </div>
            </li>
          ))}
        </ul>
        <button type="submit" className="btn btn-primary d-block mx-auto mt-4" disabled={isUploading}>
          {isUploading ? 'Yükleniyor...' : 'Anılarımızı Yükle'}
        </button>
      </form>
    </div>
  );
};

export default Upload;
