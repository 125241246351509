import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './style/styles.css';

const MemoryDetail = () => {
  const { id } = useParams();
  const [memory, setMemory] = useState(null);
  const isDownloadable = process.env.REACT_APP_DOWNLOADABLE === 'true';

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/index.php?id=${id}`)
      .then(response => setMemory(response.data))
      .catch(error => console.error('There was an error fetching the memory details!', error));
  }, [id]);

  if (!memory) {
    return <div>Loading...</div>;
  }

  if (!memory.files || !Array.isArray(memory.files)) {
    return <div>No files found</div>;
  }

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  const handleDownloadAll = async () => {
    try {
      const formData = new FormData();
    formData.append('id', memory.id);
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/index.php?download=1`, formData, {
          responseType: 'blob' });
      const blob = new Blob([response.data], { type: 'application/zip' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${memory.title}.zip`;
      document.body.appendChild(a);
      a.click();
      a.remove();
    } catch (error) {
      console.error('There was an error downloading the zip file!', error);
    }
  };

  return (
    <div className="container mt-4">
      <div className="card">
        {memory.files.length > 1 ? (
          <Slider {...settings}>
            {memory.files.map((file, index) => (
              <div key={index}>
                {file.type.startsWith('image') && (
                  <img
                    src={`${process.env.REACT_APP_API_URL}/uploads/${memory.id}/${file.filename}`}
                    className="card-img-top"
                    alt={file.originalname}
                    style={{ maxHeight: '500px', objectFit: 'cover' }}
                  />
                )}
                {file.type.startsWith('video') && (
                  <video controls className="card-img-top" style={{ maxHeight: '500px', objectFit: 'cover' }}>
                    <source src={`${process.env.REACT_APP_API_URL}/uploads/${memory.id}/${file.filename}`} type={file.type} />
                    Your browser does not support the video tag.
                  </video>
                )}
              </div>
            ))}
          </Slider>
        ) : (
          memory.files.map((file, index) => (
            <div key={index}>
              {file.type.startsWith('image') && (
                <img
                  src={`${process.env.REACT_APP_API_URL}/uploads/${memory.id}/${file.filename}`}
                  className="card-img-top"
                  alt={file.originalname}
                  style={{ maxHeight: '500px', objectFit: 'cover' }}
                />
              )}
              {file.type.startsWith('video') && (
                <video controls className="card-img-top" style={{ maxHeight: '500px', objectFit: 'cover' }}>
                  <source src={`${process.env.REACT_APP_API_URL}/uploads/${memory.id}/${file.filename}`} type={file.type} />
                  Your browser does not support the video tag.
                </video>
              )}
            </div>
          ))
        )}
        <div className="card-body">
          <h5 className="card-title">{memory.title}</h5>
          <p className="card-text">{memory.description}</p>
          {isDownloadable && (
            <button className="btn btn-primary mt-2" onClick={handleDownloadAll}>
              Anıyı İndir
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default MemoryDetail;
