import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Home from './components/home';

import LandingPage from './components/landingPage';
import 'bootstrap/dist/css/bootstrap.min.css';
import './components/style/styles.css';
import Upload from './components/Upload';
import MemoryDetail from './components/MemoryDetail';

const App = () => {
  return (
    <Router>
      <nav className="navbar navbar-expand-lg navbar-light bg-light shadow-sm">
        <div className="container">
          <Link className="navbar-brand" to="/">{process.env.REACT_APP_TITLE} <br/>Wedding Memories</Link>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <img
              alt=""
              src="https://neyfer.tech/heart.svg"
              width="50"
              height="50"
              className="d-inline-block align-top"
            />{' '}
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <Link className="nav-link" to="/home">Tüm Anılar</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/upload">Anı Oluştur</Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/home" element={<Home />} />
        <Route path="/upload" element={<Upload/>} />
        <Route path="/memory/:id" element={<MemoryDetail/>} />
        <Route component={<Home />} />
      </Routes>
    </Router>
  );
};

export default App;
