import React from 'react';
import './style/styles.css';
import { Link } from 'react-router-dom';

const LandingPage = () => {
  return (
    <div style={styles.landingPage}>
      <img src="https://i.ibb.co/85m0CWp/ramo.jpg" alt="Wedding" style={styles.backgroundImage} />
      <div style={styles.overlay}>
        <div style={styles.content}>
          <h1 style={styles.title}>Hoş Geldiniz!</h1>
          <p style={styles.landingText}>{process.env.REACT_APP_LANDING_TEXT}</p>
          <p style={styles.landingText}>{process.env.REACT_APP_LANDING_TEXT2}</p>
          
          <div style={styles.buttonGroup}>
            <Link to="/upload" className="btn btn-primary mx-2 upload-button" style={styles.button}>
              Anı Yükle
            </Link>
            <Link to="/home" className="btn btn-secondary mx-2 upload-button" style={styles.button}>
              Tüm Anıları Göster
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  landingPage: {
    position: 'relative',
    width: '100%',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    color: 'white',
  },
  backgroundImage: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    filter: 'brightness(50%)',
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    zIndex: 2,
    maxWidth: '600px',
    padding: '20px',
    background: 'rgba(0, 0, 0, 0.6)',
    borderRadius: '10px',
  },
  title: {
    fontFamily: "'Great Vibes', cursive",
    fontSize: '3rem',
    marginBottom: '20px',
    color: 'white', 
  },
  landingText: {
    fontSize: '1.5rem',
    marginBottom: '30px',
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'center', 
    gap: '10px',
  },
  button: {
    padding: '10px 20px',
    fontSize: '1.2rem',
    borderRadius: '5px',
    transition: 'background 0.3s ease',
    textDecoration: 'none',
  },
};

export default LandingPage;
